import { useEffect, useState } from 'react';
import { LngLat } from 'mapbox-gl';

import ButtonCircle from 'components/Buttons/ButtonCircle/ButtonCircle';
import { StyledIconMyLocation, StyledIconUserLocated } from './LocationControl.styles';
import { ButtonProps, TooltipProps } from 'antd';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import track from 'utils/amplitudeWrapper';
import { useFlowActions } from 'context/actions/flowActions';

interface IProps {
  buttonProps?: ButtonProps;
  tooltipProps?: TooltipProps;
  onUserLocated: (location: LngLat) => void;
  onLocationError: () => void;
  isLocationEnabled: boolean;
  mapIntersected?: boolean;
  onClick?: () => void;
}

const LocationControl = ({
  buttonProps,
  tooltipProps,
  onUserLocated,
  onLocationError,
  isLocationEnabled,
  mapIntersected = false,
  onClick,
}: IProps): JSX.Element => {
  const flowActions = useFlowActions();
  const [location, setLocation] = useState<LngLat>(
    new LngLat(MapsConstants.LONGITUDE_DEFAULT, MapsConstants.LATITUDE_DEFAULT)
  );
  const [isUserLocated, setIsUserLocated] = useState(false);
  const [firstTimeFlag, setFirstTimeFlag] = useState(false);
  const [showActive, setShowActive] = useState(false);

  const onSuccessHandler = (position: GeolocationPosition) => {
    track('user location', { 'location accepted': true });
    const { latitude, longitude } = position.coords;
    const lngLat = new LngLat(longitude, latitude);
    setLocation(lngLat);
    setIsUserLocated(true);
    onUserLocated(lngLat);
    setShowActive(true);

    flowActions.setShowSpinner({ show: false });
  };

  const onErrorHandler = () => {
    track('user location', { 'location accepted': false });
    onLocationError();
    flowActions.setShowSpinner({ show: false });
  };

  const onGetUserLocation = () => {
    if (isLocationEnabled && navigator.geolocation) {
      flowActions.setShowSpinner({ show: true });
      if (!isUserLocated) {
        navigator.geolocation.getCurrentPosition(onSuccessHandler, onErrorHandler);
      } else {
        onUserLocated(location);
        setShowActive(true);
        flowActions.setShowSpinner({ show: false });
      }
    }
    onClick?.();
  };

  useEffect(() => {
    if (isLocationEnabled) {
      onGetUserLocation();
    }
    // eslint-disable-next-line
  }, [isLocationEnabled]);

  useEffect(() => {
    if (!mapIntersected && firstTimeFlag) {
      setShowActive(false);
    }
    if (!mapIntersected) {
      setFirstTimeFlag(true);
    } else if (mapIntersected && !firstTimeFlag) {
      setFirstTimeFlag(true);
      setShowActive(true);
    }
    // eslint-disable-next-line
  }, [mapIntersected]);

  return (
    <ButtonCircle
      buttonProps={buttonProps}
      tooltipProps={{
        placement: 'topLeft',
        style: {
          zIndex: '1 !important',
        },
        className: 'tooltip-test',
        ...tooltipProps,
      }}
      iconCustom={showActive ? StyledIconUserLocated : StyledIconMyLocation}
      onClick={onGetUserLocation}
      style={{
        backgroundColor: `${showActive ? colors.green60 : ''}`,
        color: `${showActive ? colors.neutral10 : ''}`,
      }}
    />
  );
};

export default LocationControl;
