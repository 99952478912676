import { useEffect, useState } from 'react';
import {
  SectionContainer,
  Subtitle,
  VoucherContainer,
  Title,
  ButtonContainer,
  DescriptionContainer,
} from './VoucherExplanation.styles';
import { useAppState } from 'context/AppState';
import { useTranslation } from 'react-i18next';
import { isRomania } from 'utils/constants/CountryData';
import { isHungary, isPoland, isSouthAfrica, isUkraine } from 'utils/countryCode';
import ActionBar from 'pages/RecommendationV2/Sections/Header/ActionBar';
import { StyledLink, StyledParagraph } from 'components/HelpContactPopup/HelpContactPopup.styles';
import { getEmailId } from 'utils/contactEmail';
import { CropConstants, southAfricaCropwiseMailId } from 'utils/constants/Crop';

interface VoucherExplanationProps {
  isMobile: boolean;
  countryCode?: string;
  isLoading?: boolean;
  cropId?: string | number;
  pageLoadStartTime: number;
}

const VoucherExplanation = ({
  isMobile,
  countryCode,
  isLoading,
  cropId,
  pageLoadStartTime,
}: VoucherExplanationProps) => {
  const { flow } = useAppState();
  const { t } = useTranslation();
  const [localLanguage, setLocalLanguage] = useState(localStorage.getItem('i18nextLng') ?? '');
  useEffect(() => {
    const lang = flow.appLanguage;
    if (lang !== '') {
      setLocalLanguage(lang);
    }
  }, [flow.appLanguage]);

  const renderDescription = (countryCode: string | undefined) => {
    if (isSouthAfrica(countryCode)) {
      return (
        <>
          <Title>{t('Order Seeds')}</Title>
          <StyledParagraph>
            {t('Order seeds description 1')}&nbsp;
            <StyledLink href={getEmailId(CropConstants.SOUTH_AFRICA_CODE)} target="_blank">
              {getEmailId(CropConstants.SOUTH_AFRICA_CODE)}.
            </StyledLink>
            &nbsp;
            {t('Order seeds description 2')}
            <br />
            <br />
            {t('Order seeds description 3')}&nbsp;
            <StyledLink href={`mailto: ${southAfricaCropwiseMailId}`}>
              {southAfricaCropwiseMailId}.
            </StyledLink>
          </StyledParagraph>
        </>
      );
    }
    return (
      <>
        <Title>{t('Pre-book Seeds')}</Title>
        {t('Pre-book seeds description')}
      </>
    );
  };

  const showPreBookSeedsContent = () => {
    if (isHungary(countryCode) && ['en', 'hu'].includes(localLanguage)) {
      return (
        <SectionContainer isMobile={isMobile}>
          <DescriptionContainer isMobile={isMobile}>
            <Title>{t('Pre-book Seeds')}</Title>
            {t('Hungarian voucher explanation text')}
            {localLanguage === 'en' && (
              <>
                <Subtitle>{t('Hungarian voucher list title')}</Subtitle>
                <ul>
                  <li>{t('Hungarian voucher list item 1')}</li>
                  <li>{t('Hungarian voucher list item 2')}</li>
                  <li>{t('Hungarian voucher list item 3')}</li>
                </ul>
              </>
            )}
            {localLanguage === 'hu' && <Subtitle>{t('Hungarian voucher sub-text')}</Subtitle>}
          </DescriptionContainer>
          <ButtonContainer isMobile={isMobile}>
            <ActionBar
              isLoading={isLoading}
              cropId={cropId}
              pageLoadStartTime={pageLoadStartTime}
              showDownloadBtn={false}
            />
          </ButtonContainer>
        </SectionContainer>
      );
    }
    if (isRomania(countryCode) && ['en', 'ro'].includes(localLanguage)) {
      return (
        <SectionContainer isMobile={isMobile}>
          <DescriptionContainer isMobile={isMobile}>
            <Title>{t('Pre-book Seeds')}</Title>
            {t('Romanian Voucher Explanation Text')}
          </DescriptionContainer>
          <ButtonContainer isMobile={isMobile}>
            <ActionBar
              isLoading={isLoading}
              cropId={cropId}
              pageLoadStartTime={pageLoadStartTime}
              showDownloadBtn={false}
            />
          </ButtonContainer>
        </SectionContainer>
      );
    }
    if (!isPoland(countryCode) && !isUkraine(countryCode)) {
      return (
        <SectionContainer isMobile={isMobile}>
          <DescriptionContainer isMobile={isMobile}>
            {renderDescription(countryCode)}
          </DescriptionContainer>
          <ButtonContainer isMobile={isMobile}>
            <ActionBar
              isLoading={isLoading}
              cropId={cropId}
              pageLoadStartTime={pageLoadStartTime}
              showDownloadBtn={false}
            />
          </ButtonContainer>
        </SectionContainer>
      );
    } else {
      return (
        <SectionContainer isMobile={isMobile}>
          <DescriptionContainer isMobile={isMobile}>
            <Title>{t('Purchase Seeds')}</Title>
            {t('Buy Now seeds description')}
          </DescriptionContainer>
          <ButtonContainer isMobile={isMobile}>
            <ActionBar
              isLoading={isLoading}
              cropId={cropId}
              pageLoadStartTime={pageLoadStartTime}
              showDownloadBtn={false}
            />
          </ButtonContainer>
        </SectionContainer>
      );
    }
  };
  return (
    <VoucherContainer isMobile={isMobile} data-testid="voucher-explanation-text">
      {showPreBookSeedsContent()}
    </VoucherContainer>
  );
};

export default VoucherExplanation;
