/* eslint-disable array-bracket-newline */
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import React, { Dispatch, SetStateAction, useCallback, useRef, useState, useEffect } from 'react';
import { numberWithCommas } from 'utils/validator';
import {
  ControlButton,
  ControlInput,
  StyledText,
  StyledMainContainer,
  StyledLabel,
  StyledButtonContainer,
} from './InputNumberHandler.styles';
import { useTranslation } from 'react-i18next';
import './input-number-handler.styles.less';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import track from 'utils/amplitudeWrapper';
import { OptionType } from 'context/store/flowReducer';

export type Props = {
  step?: number;
  inputName: string | number;
  inputValue: string;
  setInputValue: (val: string) => void;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  min?: number;
  max?: number;
  onChange?: (value: string) => void;
  errors?: string[];
  inputProps?: { [key: string]: any };
  setHasErrorsForParent?: Dispatch<SetStateAction<{ fieldName: boolean; size: boolean }>>;
};

const InputNumberHandler: React.FC<Props> = ({
  step = 1.0,
  inputName,
  inputValue,
  setInputValue,
  label,
  placeholder,
  onChange,
  isRequired,
  min,
  max,
  setHasErrorsForParent,
  inputProps = {},
}) => {
  const [t] = useTranslation();
  const inputRef = useRef<any>();
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [cursor, setCursor] = useState<any>(null);

  const trackAmplitudeEvent = (action: string, isDecimal: boolean) => {
    if (inputProps?.optionType === OptionType.empty) {
      return;
    }

    const eventName = inputProps?.optionType === OptionType.Draw || inputProps?.optionType === OptionType.Detect
      ? 'boundaries acreage adjusted'
      : 'pin acreage adjusted';
    track(eventName, {
      [eventName]: true,
      action,
      isDecimal
    });
  };

  const validate = useCallback(
    (value: string) => {
      if (inputProps?.disabled && value === '') {
        setInputErrors([]);
        return;
      }
      const errors = [];
      const numericValue = Number.parseFloat(!value ? '0' : value.replace(/,/g, '')); // remove commas
      if (value.length < 1 && isRequired) {
        errors.push(t('This field is required'));
      }
      if (max && min && (+numericValue > max || +numericValue < min)) {
        errors.push(`${t('Input should be between')} ${min} ${t('and')} ${numberWithCommas(max)}`);
      } else if (max && +numericValue > max) {
        errors.push(`${t('Input cannot be more than')} ${numberWithCommas(max)}`);
      } else if (min && +numericValue < min) {
        errors.push(`${t('Input cannot be less than')} ${min}`);
      }
      setInputErrors(errors);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRequired, max, min, t]
  );

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart);

    const numericalValue = e.target.value?.replace(/[^\d.]/g, '');
    let finalValue ;
    if (numericalValue.endsWith('.')) {
      if (numericalValue.indexOf('.') !== numericalValue.length - 1) {
        return;
      }
      finalValue = `${new Intl.NumberFormat().format(+numericalValue) }.`;
    } else {
      const nonDecimalNumbers = numericalValue.split('.');
      const formattedValue = new Intl.NumberFormat().format(+nonDecimalNumbers[0]);
      finalValue = nonDecimalNumbers[1]
        ? `${formattedValue}.${nonDecimalNumbers[1].substring(0, 2)}`
        : formattedValue;
    }
    setInputValue(finalValue);
    validate(finalValue === '0' ? '0' : numericalValue);
    onChange?.(finalValue);
  };

  const onStep = (value: number) => {
    const nonDecimalNumbers = value.toString().split('.');
    const formattedValue = new Intl.NumberFormat().format(+nonDecimalNumbers[0]);
    const finalValue = nonDecimalNumbers[1]
      ? `${formattedValue}.${nonDecimalNumbers[1]}`
      : formattedValue;
    setInputValue(finalValue);
    validate(finalValue);
    onChange?.(finalValue);
  };

  const handleStep = (type: string) => () => {
    const numericalValue = inputValue.toString()?.replace(/[^\d.]/g, '');
    let val = parseFloat(numericalValue);
    if (!val) {
      val = 0;
    }
    const currentValue =
      type === 'up' ? Number(Number(val + step).toFixed(3)) : Number(Number(val - step).toFixed(3));
    onStep(currentValue < 0 ? 0 : currentValue);
    if (val !== currentValue) {
      trackAmplitudeEvent(type === 'up' ? 'plus_button' : 'minus_button', !Number.isInteger(currentValue));
    }
  };

  useEffect(() => {
    const hasInputErrors = inputErrors.length > 0;
    if (setHasErrorsForParent) {
      setHasErrorsForParent((prev) => {
        return { ...prev, size: hasInputErrors };
      });
    }
  }, [inputErrors, setHasErrorsForParent]);

  useEffect(() => {
    const input = document.getElementById(`input-number-handler-${inputName}`) as HTMLInputElement;
    const numberLength = input.value.length;
    if (input && Math.abs(cursor - numberLength) > 1) {
      input.setSelectionRange(cursor, cursor);
    } else if (input && Math.abs(cursor - numberLength) <= 1) {
      input.setSelectionRange(numberLength, numberLength);
    }
  }, [inputRef, cursor, inputValue, inputName]);

  useEffect(() => {
    if (inputValue === '' && inputErrors.length === 0) {
      return;
    }
    validate(inputValue);
  }, [t, inputValue, validate]);

  const ErrorList = ({ errors }: { errors: string[] }) => {
    return (
      <div>
        {errors.map((error, idx) => (
          <StyledText key={idx} type="danger">
            {error}
          </StyledText>
        ))}
      </div>
    );
  };

  return (
    <StyledMainContainer data-testid="input-number-handler">
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledButtonContainer>
        <div style={{ marginRight: '10px' }}>
          <ControlButton
            className="button-down"
            data-testid="input-number-handler-button-down"
            onClick={handleStep('down')}
            icon={<MinusOutlined color={colors.neutral60} />}
            disabled={inputProps?.disabled}
          />
        </div>
        <ControlInput
          id={`input-number-handler-${inputName}`}
          ref={inputRef}
          {...inputProps}
          inputMode="decimal"
          type="text"
          data-testid="input-number-handler-input"
          name={`size-${inputName}`}
          onChange={changeHandler}
          onBlur={(e) => {
            const numericalValue = inputValue.toString()?.replace(/[^\d.]/g, '');
            let val = parseFloat(numericalValue);
            if (!val) {
              val = 0;
            }
            trackAmplitudeEvent('manual', !Number.isInteger(val));
          }}
          value={inputValue}
          placeholder={placeholder}
          disabled={inputProps?.disabled}
          $hasErrors={inputErrors.length > 0}
          allowClear={inputErrors.length > 0}
          suffix={<span />} // This line is very important, otherwise the focus will be lost
        />

        <div style={{ marginLeft: '10px' }}>
          <ControlButton
            className="button-up"
            data-testid="input-number-handler-button-up"
            onClick={handleStep('up')}
            icon={<PlusOutlined />}
            disabled={inputProps?.disabled}
          />
        </div>
      </StyledButtonContainer>
      {inputErrors.length > 0 && <ErrorList errors={inputErrors} />}
    </StyledMainContainer>
  );
};

export default InputNumberHandler;
