import track from 'utils/amplitudeWrapper';
import ProxyLayer from 'base/api/ProxyLayer';
import { UserCategories } from 'base/types/AmplitudeClassifiers';
import { isEmail } from 'utils/validator';
import { AgronomicWeighting, AgronomicInputs } from 'context/store/recommendationFormReducer';
import { CropConstants } from 'utils/constants/Crop';
import { formatTraits, getTraitLabel } from 'utils/trait';

interface AgweightingtrackProps {
  agronomicWeightings: AgronomicWeighting[];
}
export const trackStepTwoEditingStarted = (): void => {
  const editingStepTwo = sessionStorage.getItem('editingStepTwo');
  if (editingStepTwo === null) {
    sessionStorage.setItem('editingStepTwo', 'true');
  }
};

export const trackDrawBoundaryIncomplete = (): void => {
  const isDrawingBoundary = sessionStorage.getItem('FirstBoundaryPointCreation');
  if (isDrawingBoundary) {
    track('user not finishing field creation', { 'field creation not finished': true });
    sessionStorage.removeItem('FirstBoundaryPointCreation');
  }
};

export const trackUserCategory = (email: string): void => {
  if (isEmail(email)) {
    ProxyLayer.isSyngentaUser(email).then(result => {
      track('user classification', {
        'user category': email.includes('@syngenta.co') || result.status
          ? UserCategories.SYNGENTA_USER
          : UserCategories.NON_SYNGENTA_USER,
      });
    }).catch(() => {
      console.error('isSyngentaUser error');
      track('user classification', {
        'user category': email.includes('@syngenta.co')
          ? UserCategories.SYNGENTA_USER
          : UserCategories.NON_SYNGENTA_USER,
      });
    });
  }
};

export const trackAgweightings = ({agronomicWeightings}: AgweightingtrackProps): void => {
  const changedWeightings = agronomicWeightings.filter((lw) => lw.value > 0);
  changedWeightings.forEach((attribute) => {
    track('ag weightings', { weigthing: attribute.attributeName,});
  });
  track('ag weightings', { 'ag weightings applied': changedWeightings.length});
};

export const trackSunflowerInputs = (agronomicInputs: AgronomicInputs): void => {
  track('intensity for sunflower', { 'sunflower intensity': agronomicInputs.rotationIntensity });
  track('tillage practice', {
    tillage:
      agronomicInputs.tillagePractice === CropConstants.NO_TILLAGE
        ? CropConstants.MINIMAL_TILLAGE
        : agronomicInputs.tillagePractice,
  });
  const formatedTrait = formatTraits(agronomicInputs.selectedHerbicideTrait);
  const herbicideTechnologyNames = formatedTrait
    .map((tName: string) => getTraitLabel(tName))
    .filter((tName: string) => tName);
  if (herbicideTechnologyNames.length > 0) {
    track('herbicide technology', { herbicide: herbicideTechnologyNames.join(', ') });
  }
  track('broomrape infestation', { broomRape: agronomicInputs.isBroomrapeInfestation });
  if (agronomicInputs.selectedBroomrape.length > 0) {
    track('broomrape resistance technology', {
      'broomrape resistance': agronomicInputs.selectedBroomrape.join(', '),
    });
  }
  track('crop segment', { 'crop segment selected': agronomicInputs.selectedSegment.join(', ') });
};
