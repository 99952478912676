import { useState } from 'react';
import {
  StyledButton,
  StyledModalView,
} from './ShareAndContactBottomSheet.styles';
import { useTranslation } from 'react-i18next';
import { ButtonType, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import openMapNotification from 'utils/openMapNotification';
import { StyledNotificationContent } from 'components/Maps/Maps.styles';
import Phone from 'assets/icons/phone_24px.svg';
import {
  ShareAltOutlined
} from '@ant-design/icons';
import IconClose from 'components/Icons/IconClose';
import { useUrl } from 'nextjs-current-url';
import copy from 'copy-to-clipboard';

interface ShareAndContactBottomSheetProps {
  onClickContact: Function;
  onClickShare: Function;
  onClickOutside?: Function;
}

const ShareAndContactBottomSheet = ({
  onClickContact,
  onClickShare,
  onClickOutside,
}: ShareAndContactBottomSheetProps): JSX.Element => {
  const [t] = useTranslation();
  const [slideDirection, setSlideDirection] = useState(ModalAnimationDirections.Up);
  const [isAnimating, setIsAnimating] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const { href: currentUrl } = useUrl() ?? {};
  const screenWidth = window.innerWidth;
  const notificationWidth = screenWidth > 0 ? screenWidth - 15 : 350;

  const handleAnimationEnd = () => {
    if (isAnimating) {
      if (onClickOutside) {
        onClickOutside();
      } else {
        onClickContact();
      }
    }
    if (nextClicked) {
      onClickShare();
      if (!showNotification) {
        return;
      }
      openMapNotification({
        type: 'success',
        id: 'copy-recommendation-link',
        msg: (
          <StyledNotificationContent>
            {t('Recommendation weblink copied')}
          </StyledNotificationContent>
        ),
        duration: 10,
        placement: 'bottomLeft',
        style: {
          right: 20,
          width: notificationWidth,
        },
        closeIcon: <IconClose color={colors.neutral40} width={18} height={18} />,
        onClose: () => setShowNotification(true),
      });
      setShowNotification(false);
    }
  };

  const handleShareClick = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    if (currentUrl) {
      copy(currentUrl);
      setNextClicked(true);
    }
  };

  const handleContactClick = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    onClickContact();
  };

  const handleCloseDrawer = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    setIsAnimating(true);
  };

  return (
    <StyledModalView
      width={494}
      isViewFooter
      showHeaderRectangle
      onCancel={handleCloseDrawer}
      footer={
        <>
          <StyledButton
            data-testid="contact-us-button-footer"
            type={ButtonType.default}
            size={'large'}
            onClick={handleContactClick}
          >
            <img
              src={Phone}
              alt="No img found"
              className='phoneIconClass'
            />
            {t('Contact us')}
          </StyledButton>
          <StyledButton
            type={ButtonType.primary}
            size={'large'}
            onClick={handleShareClick}
          >
            <ShareAltOutlined />
            {t('Copy & Share Weblink')}
          </StyledButton>
        </>
      }
      slideDirection={slideDirection}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default ShareAndContactBottomSheet;
