/* eslint-disable no-confusing-arrow */
/* eslint-disable array-bracket-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useEffect, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';

import { Root, StepContainer, LandscapeWarningMessageContainer } from './BoundaryWizard.styles';

import { useFlowActions } from 'context/actions/flowActions';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useApiDataActions, ActionTypes } from 'context/actions/ApiDataActions';
import { useAppState, useAppDispatch } from 'context/AppState';
import { FlowSteps, ModeTypes, OptionType } from 'context/store/flowReducer';
import useValidationSteps from './hooks/useValidationSteps';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Header from 'containers/Header/Header';
import ProgressBar from 'components/ProgressBar';
import DeleteNotificationModal from 'components/DeleteBoundaryNotificationModal';
import MapView from 'containers/MapView/DrawBoundaryMapView';
import FirstStep from 'containers/FirstStep';
import RelocatePositionModal from 'components/RelocatePositionModal/RelocatePositionModal';
import openMapNotification from 'utils/openMapNotification';
import EnvironmentDrawer from 'components/EnvironmentDrawer/EnvironmentDrawer';
import { ConfirmationModal } from 'components/ConfirmationModal';
import TabsView from 'components/TabsView';
import { useBreakpoint } from 'hooks';
import SecondStep from 'containers/SecondStep';
import track from 'utils/amplitudeWrapper';
import { Spinner } from 'components/Spinner';
import { GetRecommendationModal } from 'components/GetRecommendationModal';
import routes from 'base/constants/routes';
import { ICrop } from 'base/types/Crop';
import AgronomicWeightingsDrawer from 'components/AgronomicWeightingsDrawer';
import { AgronomicWeighting } from 'context/store/recommendationFormReducer';
import { CropConstants } from 'utils/constants/Crop';
import { AgInputType } from 'base/types/AmplitudeClassifiers';
import { RotationIntensity, RotationIntensityforDowny } from 'utils/constants/RotationIntensity';
import { calculateSoilType } from 'utils/calculateSoilType';
import { TillagePractice, TillagePracticeForTraits } from 'utils/constants/TillagePractice';
import {
  trackDrawBoundaryIncomplete,
  trackAgweightings,
  trackSunflowerInputs,
} from 'utils/helpers/amplitude';

import { formatYieldRangeLabel } from 'utils/yieldRange';
import { ProductListEmptyReponse, checkIfProductListIsEmpty } from 'utils/helpers/filterProducts';
import { ORIENTATION_BASED_DISPLAY, ORIENTATION_TYPE } from 'utils/constants/BrowserConstants';
import { hideCookiebotIcon } from 'utils/helpers/cookiebot';

import {
  retrieveBoundariesData,
  retrieveRecommendationInputData,
} from 'utils/getRecommendationInputData';
import { DownyMildewResponse } from 'base/types/DownyMildew';
import ProxyLayer from 'base/api/ProxyLayer';
import { notification } from 'antd';
import { getCentroidForCircle, getCentroidOfFields } from 'utils/getCentroidFields';
import { Country } from 'base/types/Countries';
import { AttributesForFields } from 'base/types/Fields';
import { ContentViewMobile } from 'pages/DropAPinFlow/DropAPinFlow.styles';
import { checkIfNoSeedsVarietiesAvailable } from 'utils/getRecommendedSeeds';

interface BoundaryWizardProps {
  isMobileLongTermChanges?: boolean;
}

const BoundaryWizard = (props: BoundaryWizardProps) => {
  const { isMobileLongTermChanges } = props;
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { isMobile, orientation } = screens;
  const isLeftPanelOpen = isMobile ? false : true;
  const flowActions = useFlowActions();
  const recommendationActions = useRecommendationFormActions();
  const ApiDataActions = useApiDataActions();
  const appDispatcher = useAppDispatch();
  const { pathname } = useLocation();
  const {
    flow: {
      resetBoundaryValues,
      showDeleteBoundaryModal,
      currentStep,
      deleteBoundaryAction,
      currentModeType,
      previousModeType,
      showCancelBoundaryModal,
      showDrawingWarning,
      showEditBoundaryModal,
      optionType,
      showEnvironmentDrawer,
      areBoundariesConfirmed,
      mobileActiveTab,
      showSpinner,
      showInvalidBoundaryModal,
      showGetRecommendationModal,
      localCountry,
      showAgronomicWeightings,
      journeyCompleted,
      showPrompt,
      addAnother,
      isDrawingBoundaries,
      isContinueButtonActive,
      isAnotherRecommendationCreated,
      showLandIsNotArableModal,
    },
    recommendationForm: {
      fields,
      fieldSelected,
      fieldNameForm,
      isAtleastAVertex,
      tempFields,
      fieldSnapshots,
      rankingCrop,
      countryCode,
      countryRegion,
      agronomicInputs,
      tempBoundaryField,
      selectedCrop,
      agronomicWeightings,
      currentFieldArea,
    },
    apiData: {
      countryList,
      attributes,
      tppData,
      downyMildew,
      recommenationValidateLoading,
      noSeedsAvailable,
      fieldsAttributes,
      fieldsAttributesLoading,
    },
  } = useAppState();
  const {
    plantingDate,
    yieldRangeId,
    yieldRangeLabel,
    harvestDate,
    agProducts,
    rotationIntensity,
    tillagePractice,
    selectedHerbicideTrait,
    selectedSegment,
    isBroomrapeInfestation,
    selectedBroomrape,
  } = agronomicInputs;
  const {
    setFlowStep,
    setCurrentModeType,
    setPreviousModeType,
    setDeleteBoundaryAction,
    setShowDeleteBoundaryModal,
    setShowCancelBoundaryModal,
    setResetBoundaryValues,
    setShowDrawingWarning,
    setMobileActiveTab,
    setShowEditBoundaryModal,
    setShowEnvironmentDrawer,
    setShowGetRecommendationModal,
    setShowInvalidBoundaryModal,
    setListTabIsDisabled,
    setDisabledButtonNext,
    setIsDrawingBoundaries,
    setShowAgronomicWeightings,
    setAddAnother,
    setShowEnvironmentInformationModal,
    setShowLandIsNotArableModal,
    setShowEnvironmentPopup,
    setContinueButtonActive,
    setIsLongTermMobileDesign,
  } = useFlowActions();
  const {
    deleteField: deleteFieldById,
    setFieldSelected,
    setTempBoundaryField,
    setTempFields,
    saveFields,
    setSelectedCrop,
    fillAgronomicWeightings,
    setIsAtleastAVertex
  } = useRecommendationFormActions();

  const {
    previousStepMap,
    disabledNextBtn,
    disabledPrevBtn,
    nextStepMap,
    nextButtonText,
    backButtonText,
  } = useValidationSteps({
    currentStep,
    currentModeType,
    agProducts,
    plantingDate,
    selectedCrop,
    yieldRangeId,
    rotationIntensity,
    tillagePractice,
    selectedHerbicideTrait,
    selectedSegment,
    isBroomrapeInfestation,
    selectedBroomrape,
    isContinueButtonActive,
    isAnotherRecommendationCreated,
  });
  const [disableListTab, setDisableListTab] = useState(true);
  const [checkProductListEmptyResponse, setCheckProductListEmptyResponse] = useState({
    errorMsg: '',
    isProductListEmpty: false,
  });
  const { isValidationApiCheckEnabled, isLongTermChangesEnable } = useFlags();

  useLayoutEffect(() => {
    hideCookiebotIcon();
  }, []);

  useEffect(() => {
    if (!optionType) {
      navigate('/');
    }
  }, [navigate, optionType]);

  useEffect(() => {
    if (optionType) {
      setCurrentModeType({ modeType: ModeTypes.CREATING });
      setIsLongTermMobileDesign({
        isLongTermMobileDesign: isMobile && isLongTermChangesEnable,
      });
    }
  }, [optionType]);

  useEffect(() => {
    if (
      optionType &&
      !fields.length &&
      !showDeleteBoundaryModal &&
      currentModeType !== ModeTypes.EDITING &&
      currentModeType !== ModeTypes.CONFIRMING_AFTER_EDIT
    ) {
      setCurrentModeType({ modeType: ModeTypes.CREATING });
    }
  }, [
    optionType,
    fields,
    showDeleteBoundaryModal,
    currentModeType,
    tempBoundaryField,
    previousModeType,
    isAtleastAVertex,
  ]);

  useEffect(() => {
    // Disable or Enable List Tab
    if (!isMobile || (isMobile && fields.length === 0)) {
      setDisableListTab(!areBoundariesConfirmed);
      setListTabIsDisabled({ isDisabled: !areBoundariesConfirmed });
    }
  }, [areBoundariesConfirmed]);

  useEffect(() => {
    const handleTabClose = () => {
      if (!journeyCompleted) {
        track('journey', { 'journey boundary completed': false });
      }
      trackDrawBoundaryIncomplete();
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => window.removeEventListener('beforeunload', handleTabClose);
  }, [journeyCompleted]);

  useEffect(() => {
    if (isMobile && currentModeType === ModeTypes.EDITING) {
      setListTabIsDisabled({ isDisabled: false });
      setDisableListTab(false);
    }
  }, [currentModeType]);

  useEffect(() => {
    if (isMobile && currentModeType === ModeTypes.SAVING && isAnotherRecommendationCreated) {
      setListTabIsDisabled({ isDisabled: false });
      setDisableListTab(false);
    }
  });

  const displayDeletedFieldNotification = useCallback(() => {
    const fieldName = fieldSelected ? fieldSelected.fieldName : fieldNameForm;
    let container = undefined;
    let placement = 'bottom';
    if (isMobile) {
      if (
        (mobileActiveTab === 'List' && fields.length !== 1) ||
        (mobileActiveTab === 'Map' && fields.length >= 1)
      ) {
        container = document.getElementById('save-boundary-modal');
      } else {
        placement = 'top';
      }
    }
    openMapNotification({
      id: 'boundaryDeleted',
      className: 'toast-notification-with-icon',
      placement,
      duration: 5,
      msg: 'fieldDeletion',
      msgProps: { fieldName },
      width: 250,
      container,
    });
  }, [fieldSelected, fieldNameForm, isMobile, mobileActiveTab, fields]);

  const validateRecommendationApi = async (
    dmValue?: DownyMildewResponse,
    fieldAttributeResponse?: AttributesForFields
  ) => {
    const otherAgInput = {
      tppData,
      downyMildew: dmValue,
      selectedCrop,
    };
    const nextStep = nextStepMap[currentStep];
    try {
      const boundaries = retrieveBoundariesData(fields);
      const recommendationInput = retrieveRecommendationInputData(
        agronomicInputs,
        otherAgInput,
        i18n,
        countryCode,
        countryRegion,
        agronomicWeightings,
        rankingCrop,
        t,
        countryList,
        attributes,
        fieldAttributeResponse,
      );
      const body = {
        recommendationInput,
        boundaries,
      };
      await ApiDataActions.getRecommendationValidatedBeforeHand(body).then((result) => {
        const hasNoneValue = checkIfNoSeedsVarietiesAvailable(result);
        if (!hasNoneValue && nextStep) {
          setFlowStep({ step: nextStep });
        }
        setShowGetRecommendationModal({ show: true });
      });
    } catch (e) {
      setShowGetRecommendationModal({ show: false });
      appDispatcher({
        type: ActionTypes.setDownyMildewError,
        payload: { error: e },
      });
    }
  };

  const getCountryName = () => {
    const selectedCountry = countryList?.find((item: Country) => item.shortCode === countryCode);
    return selectedCountry?.name;
  };

  const fetchFieldAttributesToFilterProducts = async (dmValue: any) => {
    let centroid: number[] = [];
    if (fields.length) {
      if ([OptionType.Draw, OptionType.Detect].includes(optionType)) {
        centroid = getCentroidOfFields(fields);
      } else if (optionType === OptionType.Drop) {
        centroid = getCentroidForCircle(fields);
      }
    }
    try {
      if (centroid.length > 0) {
        const lat = centroid[1];
        const long = centroid[0];
        await ApiDataActions.getFieldAttributes(getCountryName(), selectedCrop?.id, long, lat)
          .then((fieldAttributeResponse) => {
            appDispatcher({
              type: ActionTypes.setFieldsAttribute,
              payload: { data: fieldAttributeResponse },
            });
            validateRecommendationApi(dmValue, fieldAttributeResponse);
          })
          .catch(() => {
            validateRecommendationApi(dmValue);
          });
      }
    } catch (e) {
      appDispatcher({
        type: ActionTypes.setFieldsAttributeError,
        payload: { error: e },
      });
    }
  };

  const checkBeforeGenerateRecommendation = async (dmValue?: DownyMildewResponse) => {
    const otherAgInput = {
      tppData,
      downyMildew: dmValue,
      selectedCrop,
    };
    const result: ProductListEmptyReponse = checkIfProductListIsEmpty(
      otherAgInput,
      agronomicInputs,
      t,
      countryCode,
      fieldsAttributes
    );
    setCheckProductListEmptyResponse(result);

    if (!result?.isProductListEmpty) {
      // added a flag to manage validation api check
      if (isValidationApiCheckEnabled) {
        if (countryCode === CropConstants.BRAZIL_CODE) {
          await fetchFieldAttributesToFilterProducts(dmValue);
        } else {
          await validateRecommendationApi(dmValue);
        }
      } else {
        const nextStep = nextStepMap[currentStep];
        if (nextStep) {
          flowActions.setFlowStep({ step: nextStep });
        }
        flowActions.setShowGetRecommendationModal({ show: true });
      }
    }
  };

  const getDownyMildew = useCallback(async () => {
    try {
      if (
        rotationIntensity &&
        tillagePractice &&
        tillagePractice !== TillagePractice.NO_TILLAGE &&
        tppData.TPP_group &&
        tppData.TPP_group.length &&
        fields
      ) {
        const rotation =
          rotationIntensity === RotationIntensity.Extensive
            ? RotationIntensityforDowny.Extensive
            : RotationIntensityforDowny.Intensive;

        const tillPractice =
          tillagePractice === TillagePractice.PLOUGHING
            ? TillagePracticeForTraits.PLOUGHING
            : TillagePracticeForTraits.MINIMAL_TILLAGE;

        const dmValue = await ApiDataActions.getDownyMildew(
          tppData.TPP_group[0],
          rotation,
          tillPractice,
          calculateSoilType(fields)
        );

        if (dmValue.value) {
          checkBeforeGenerateRecommendation(dmValue);
        }
      }
      setShowGetRecommendationModal({ show: true });
      setMobileActiveTab({ activeTab: 'List' });
    } catch (e) {
      appDispatcher({
        type: ActionTypes.setDownyMildewError,
        payload: { error: e },
      });
    }
  }, [
    tppData,
    rotationIntensity,
    tillagePractice,
    fields,
    plantingDate,
    agProducts,
    selectedHerbicideTrait,
    selectedBroomrape,
    selectedSegment,
    harvestDate,
    downyMildew,
  ]);

  const checkIsLandArable = async () => {
    const cancelToken = ProxyLayer.cancelToken();
    try {
      const latLng = tempBoundaryField?.properties?.center;
      const [longitude, latitude] = latLng;
      await ApiDataActions.isLandArable(longitude, latitude, cancelToken.token);
    } catch (e) {
      console.log('Error!!', e);
    } finally {
      cancelToken?.cancel();
    }
  };

  const handleNextAction = useCallback(() => {
    const nextStep = nextStepMap[currentStep];
    switch (currentStep) {
      case FlowSteps.STEP1:
        // For mobile layout, Next button of step 1 is getting called from here only.
        if (isMobile) {
          if (!isContinueButtonActive) {
            if (isDrawingBoundaries) {
              flowActions.setSaveWithoutClosingBoundary({
                saveWithoutClosingBoundary: true,
              });
            } else {
              checkIsLandArable();
            }
            return;
          } else {
            flowActions.setMobileActiveTab({ activeTab: 'List' });
            setListTabIsDisabled({ isDisabled: false });
            notification.close('environmentInfoMobileNotification');
            setShowEnvironmentInformationModal({ show: false });
            return;
          }
        }
        break;
      case FlowSteps.STEP2:
        if (selectedCrop?.name) {
          /*
           * agInputs for testing none usecase for planting date - Start
           */
          const agInputs: AgInputType = {
            'ag input crop': selectedCrop.name,
          };
          if (yieldRangeLabel.length > 0) {
            agInputs['yield range'] = formatYieldRangeLabel(yieldRangeLabel);
          }
          if (plantingDate) {
            agInputs['planting date'] = plantingDate?.format('YYYY-MM-DD');
          }
          if (harvestDate) {
            agInputs['harvest date'] = harvestDate.format('YYYY-MM-DD');
            track('harvest date', { 'harvest date selected': true });
          }
          track('agronomic inputs', { ...agInputs });
          /*
           *  agInputs for testing none usecase for planting date - End
           */
        }
        trackAgweightings({agronomicWeightings});
        if (selectedCrop?.id === CropConstants.SUNFLOWER_CROP_ID) {
          trackSunflowerInputs(agronomicInputs);
          getDownyMildew();
        } else {
          checkBeforeGenerateRecommendation();
          setMobileActiveTab({ activeTab: 'List' });
          if (nextStep) {
            setFlowStep({ step: nextStep });
          }
        }
        break;
      default:
        break;
    }
  }, [
    currentStep,
    isMobile,
    currentModeType,
    selectedCrop,
    flowActions,
    yieldRangeId,
    plantingDate,
    harvestDate,
    nextStepMap,
    fields,
    selectedHerbicideTrait,
    selectedBroomrape,
    selectedSegment,
    tppData,
    downyMildew,
    selectedCrop,
    isDrawingBoundaries,
    isContinueButtonActive,
    tempBoundaryField,
    agronomicInputs,
    agronomicWeightings
  ]);

  const handlePreviousAction = useCallback(() => {
    if (currentStep === FlowSteps.STEP2) {
      const emptyCrop: ICrop = {
        id: undefined,
        name: '',
        type: '',
      };
      setSelectedCrop({ crop: emptyCrop });
    }

    if (isMobile) {
      flowActions.setShowCropTypeDrawer({ show: false });
      flowActions.setCurrentModeType({ modeType: ModeTypes.EDITING });
      recommendationActions.setEnableFieldSaveButton({ enableFieldSaveButton: true });
      flowActions.setCurrentStep({ step: FlowSteps.STEP1 });
      flowActions.setShowFieldInformationSheet({ showFieldInformationSheet: true });
      navigate(routes.home);
    } else {
      const previousStep = previousStepMap[currentStep];
      if (previousStep) {
        setFlowStep({ step: previousStep, type: OptionType.Draw });
      }
    }
  }, [currentStep, optionType]);

  const handleDeleteNotificationModalOnCancel = useCallback(() => {
    setCurrentModeType({ modeType: previousModeType });
    setFieldSelected({ field: undefined });
    setShowDeleteBoundaryModal({ show: false });
    if (isMobile) {
      if (mobileActiveTab === 'Map') {
        setCurrentModeType({ modeType: ModeTypes.CREATING });
      } else if (mobileActiveTab === 'List') {
        setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
      }
    }
  }, [mobileActiveTab, previousModeType]);

  const setNextModeType = useCallback(() => {
    const newModeType = fields.length ? ModeTypes.NO_ACTION : ModeTypes.CREATING;
    setCurrentModeType({ modeType: newModeType });
  }, [fields]);

  const deleteField = useCallback(() => {
    if (fieldSelected) {
      deleteFieldById({ fieldId: fieldSelected.id });
    }
  }, [fieldSelected]);

  const updateMobileActiveTab = useCallback(() => {
    if (isMobile) {
      if (
        fields.length > 0 &&
        (currentModeType === ModeTypes.CREATING ||
          previousModeType === ModeTypes.EDITING ||
          currentModeType === ModeTypes.EDITING)
      ) {
        flowActions.setMobileActiveTab({ activeTab: 'List' });
      } else if (fields.length === 1 && currentModeType !== ModeTypes.CREATING) {
        if (mobileActiveTab === 'List') {
          flowActions.setMobileActiveTab({ activeTab: 'Map' });
          setIsDrawingBoundaries({ isDrawing: true });
        }
      }
    }
  }, [currentModeType, previousModeType]);

  const closeEnvironmentInfoModal = () => {
    if (isMobile) {
      notification.close('environmentInfoMobileNotification');
      setShowEnvironmentInformationModal({ show: false });
    } else {
      const element = document.querySelector('.environment-info-popup') as HTMLElement;
      if (element) {
        notification.close('environmentInfoPopup');
        setShowEnvironmentPopup({ show: false });
      }
    }
  };

  const handleDeleteNotificationModalOnConfirm = useCallback(() => {
    setShowDeleteBoundaryModal({ show: false });
    deleteField();
    displayDeletedFieldNotification();
    setFieldSelected({ field: undefined });
    updateMobileActiveTab();
    setNextModeType();
    setDeleteBoundaryAction({ deleteBoundaryAction: !deleteBoundaryAction });
    setPreviousModeType({ modeType: ModeTypes.DELETING });
    setIsAtleastAVertex({ isAtleastAVertex: false });

    if (fields.length) {
      setDisabledButtonNext({ disabled: false });
      setListTabIsDisabled({ isDisabled: false });
      setDisableListTab(false);
    }

    if (isMobile) {
      if (fields.length <= 1) {
        setContinueButtonActive({ active: false });
      }
      if (mobileActiveTab !== 'List' && fields.length > 0) {
        setMobileActiveTab({ activeTab: 'List' });
      }
    }
    closeEnvironmentInfoModal();
  }, [
    fields,
    deleteBoundaryAction,
    setNextModeType,
    deleteField,
    displayDeletedFieldNotification,
    mobileActiveTab,
  ]);

  const displayCancelledBoundaryNotification = useCallback(() => {
    const fieldName = fieldSelected ? fieldSelected.fieldName : fieldNameForm;
    openMapNotification({
      id: 'boundaryCanceled',
      placement: 'bottom',
      duration: 5,
      msg: t('boundaryCanceled', { fieldName }),
      width: 250,
      isLeftPanelOpen,
      style: isMobile ? { marginBottom: '50px' } : undefined,
      container: isMobile ? document.getElementById('save-boundary-modal') : undefined,
    });
  }, [fieldSelected, fieldNameForm, isMobile]);

  const handleGoBackModalConfirm = useCallback(() => {
    setShowCancelBoundaryModal({ show: false });
    if (fields.length && !isMobile) {
      if (currentModeType === ModeTypes.EDITING) {
        setTempFields({ fields });
      }
      if (currentModeType === ModeTypes.CREATING) {
        setMobileActiveTab({ activeTab: 'List' });
      }
      setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
      displayCancelledBoundaryNotification();
      setTempBoundaryField({ boundary: undefined });
    } else if (
      currentModeType === ModeTypes.EDITING ||
      (currentModeType === ModeTypes.CONFIRMING_AFTER_EDIT && !isMobile)
    ) {
      setTempFields({ fields });
      setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
      setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
      displayCancelledBoundaryNotification();
    } else if (currentModeType === ModeTypes.CREATING && isMobile && addAnother) {
      setMobileActiveTab({ activeTab: 'List' });
      setTempBoundaryField({ boundary: undefined });
      displayCancelledBoundaryNotification();
      setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
      setAddAnother({ addAnother: false });
    } else {
      navigate('/');
    }
  }, [fields, resetBoundaryValues, displayCancelledBoundaryNotification, currentModeType]);

  const onOkRelocateBoundaryModal = useCallback(() => {
    setShowDrawingWarning({ show: false });
  }, []);

  const prepareEditing = useCallback(() => {
    setTempBoundaryField({ boundary: fieldSelected?.boundary });
    setTempFields({ fields });
    fieldSelected?.id && deleteFieldById({ fieldId: fieldSelected?.id });
    setFieldSelected({ field: undefined });
  }, [fieldSelected, fields]);

  const handleConfirmEditBoundary = useCallback(() => {
    setShowEditBoundaryModal({ show: false });
    isAtleastAVertex && setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    if (currentModeType === ModeTypes.EDITING) {
      saveFields({ fields: tempFields });
    }
    setCurrentModeType({ modeType: ModeTypes.EDITING });
    prepareEditing();
  }, [resetBoundaryValues, isAtleastAVertex, prepareEditing]);

  const handleConfirmRelocateToBoundary = useCallback(() => {
    setShowInvalidBoundaryModal({ show: false });
    setCurrentModeType({ modeType: ModeTypes.SAVING });
    setListTabIsDisabled({ isDisabled: false });
    setIsDrawingBoundaries({ isDrawing: false });
  }, []);

  const handleUpdateEnvironmentDrawer = useCallback(() => {
    setShowEnvironmentDrawer({ show: false });
    flowActions.setMobileActiveTab({ activeTab: 'List' });

    if (currentFieldArea || fields.length > 0) {
      openMapNotification({
        id: 'updateEnvironment',
        msg: t('Soil type has been updated.'),
        width: 250,
        placement: isMobile ? 'top' : 'bottom',
        duration: 5,
      });
    }
  }, [currentFieldArea, isMobile]);

  const stepsControlProps = useMemo(() => {
    if (currentModeType === ModeTypes.EDITING) {
      return {
        buttonPreviousProps: {
          text: t('Cancel'),
          icon: undefined,
          onClick: () => {
            setMobileActiveTab({ activeTab: 'List' });
          },
          buttonProps: {
            disabled: false,
          },
        },
        buttonNextProps: {
          text: t('Save'),
          icon: undefined,
          onClick: () => {
            setMobileActiveTab({ activeTab: 'List' });
          },
          buttonProps: {
            disabled: fieldSnapshots.length < 2,
          },
        },
      };
    } else if (currentModeType === ModeTypes.CREATING && fields.length > 0 && !isMobile) {
      return {
        buttonPreviousProps: {
          text: t('Cancel'),
          icon: undefined,
          onClick: () => {
            setShowCancelBoundaryModal({ show: true });
          },
          buttonProps: {
            disabled: false,
          },
        },
        buttonNextProps: {
          text: t('Save'),
          icon: undefined,
          onClick: () => {
            setMobileActiveTab({ activeTab: 'List' });
          },
          buttonProps: {
            disabled: !areBoundariesConfirmed,
          },
        },
      };
    } else {
      return {
        buttonPreviousProps: {
          'aria-label': backButtonText,
          onClick: () =>
            isMobile ? setShowCancelBoundaryModal({ show: true }) : handlePreviousAction(),
        },
        buttonNextProps: {
          'aria-label': nextButtonText,
          onClick: () => handleNextAction(),
        },
      };
    }
  }, [
    handleNextAction,
    nextButtonText,
    handlePreviousAction,
    t,
    fieldSnapshots,
    backButtonText,
    currentModeType,
  ]);

  const stepComponentMap = useMemo(
    (): { [key in FlowSteps]: JSX.Element | undefined } => ({
      [FlowSteps.STEP1]: <FirstStep />,
      [FlowSteps.STEP2]: (
        <SecondStep
          onClickPrev={handlePreviousAction}
          onClickNext={handleNextAction}
          isCornSelected={
            selectedCrop?.name === CropConstants.CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SILAGE_CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SUMMER_CORN_SMALLCASE
          }
          isMobile={isMobile}
        />
      ),
      [FlowSteps.STEP3]: (
        <SecondStep
          onClickPrev={handlePreviousAction}
          onClickNext={handleNextAction}
          isCornSelected={
            selectedCrop?.name === CropConstants.CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SILAGE_CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SUMMER_CORN_SMALLCASE
          }
          isMobile={isMobile}
        />
      ),
      [FlowSteps.STEP4]: undefined,
      [FlowSteps.empty]: undefined,
    }),
    [handleNextAction, handlePreviousAction]
  );

  const RightPanel = useMemo(
    () => <MapView stepControlsProps={stepsControlProps} />,
    [stepsControlProps]
  );

  const mobileTabsItems = useMemo(
    () => [
      {
        key: 'Map',
        label: 'Map',
        children: RightPanel,
        forceRender: true,
      },
      {
        key: 'List',
        label: 'List',
        children: stepComponentMap[currentStep],
        forceRender: true,
        disabled: disableListTab,
      },
    ],
    [RightPanel, stepComponentMap, currentStep, disableListTab]
  );

  const onCancelRecommendationModal = useCallback(() => {
    flowActions.setShowGetRecommendationModal({ show: false });
    flowActions.setFlowStep({ step: FlowSteps.STEP2 });
  }, [flowActions]);

  const onGetRecommendationModal = useCallback(
    (status: string) => {
      if (status === 'in-progress') {
        navigate(routes.recommendationInProgress);
      } else {
        navigate(routes.confirmation);
      }
    },
    [navigate]
  );

  const handleSetShowAgronomicWeightings = useCallback((showAgronomicWeightings: boolean) => {
    setShowAgronomicWeightings({ show: showAgronomicWeightings });
  }, []);

  const handleFillAgronomicWeightings = useCallback((agronomicWeighting: AgronomicWeighting[]) => {
    fillAgronomicWeightings({ agronomicWeighting: agronomicWeighting });
  }, []);

  usePrompt({
    when: pathname === routes.wizard && showPrompt && !isMobile,
    message: t('Are you sure you want to navigate away from this page? All progress will be lost.'),
  });

  const handleShowLandNotArableModal = () => {
    setPreviousModeType({ modeType: ModeTypes.DELETING });
    deleteField();
    setFieldSelected({ field: undefined });
    setCurrentModeType({ modeType: ModeTypes.CREATING });
    setDeleteBoundaryAction({ deleteBoundaryAction: !deleteBoundaryAction });
    if (fields.length) {
      setDisabledButtonNext({ disabled: false });
      setListTabIsDisabled({ isDisabled: false });
      setDisableListTab(false);
    }
    setIsDrawingBoundaries({ isDrawing: true });
    setShowLandIsNotArableModal({ show: false });
  };

  return (
    <>
      <Root
        className={
          isMobile && orientation === ORIENTATION_TYPE.LANDSCAPE && !showEnvironmentDrawer
            ? ORIENTATION_BASED_DISPLAY.HIDE_ON_LANDSCAPE
            : ''
        }
      >
        <Header />
        {(showSpinner || recommenationValidateLoading || fieldsAttributesLoading) && <Spinner />}
        <ProgressBar
          handleNextAction={handleNextAction}
          handlePreviousAction={handlePreviousAction}
          disabledPrevBtn={disabledPrevBtn}
          disabledNextBtn={disabledNextBtn}
          nextStepText={nextButtonText}
          backButtonText={backButtonText}
          showProgressLine
        />
        {isMobileLongTermChanges ? (
          <ContentViewMobile>
            <SecondStep
              onClickPrev={handlePreviousAction}
              onClickNext={handleNextAction}
              isCornSelected={
                selectedCrop?.name === CropConstants.CORN_SMALLCASE ||
                selectedCrop?.name === CropConstants.SILAGE_CORN_SMALLCASE ||
                selectedCrop?.name === CropConstants.SUMMER_CORN_SMALLCASE
              }
              isMobile={true}
            />
          </ContentViewMobile>
        ) : (
          <StepContainer>
            {!isMobile ? (
              <>
                {stepComponentMap[currentStep]}
                {RightPanel}
              </>
            ) : (
              <TabsView
                items={mobileTabsItems}
                activeKey={mobileActiveTab}
                onChange={(selectedKey) =>
                  flowActions.setMobileActiveTab({ activeTab: selectedKey as any })
                }
              />
            )}
          </StepContainer>
        )}
        {showDeleteBoundaryModal && (
          <DeleteNotificationModal
            handleCancelClick={handleDeleteNotificationModalOnCancel}
            handleConfirmClick={handleDeleteNotificationModalOnConfirm}
            isConfirmButtonDisabled={false}
            fieldName={fieldSelected ? fieldSelected.fieldName : fieldNameForm}
          />
        )}

        {showInvalidBoundaryModal && (
          <ConfirmationModal
            title={t('Invalid Boundary')}
            body={t(
              'We are unable to add this boundary. Please ensure that all boundaries are located within your region/country.'
            )}
            confirmButtonText={t('Locate existing boundaries')}
            onClickConfirm={() => handleConfirmRelocateToBoundary()}
          />
        )}
        {showCancelBoundaryModal && (
          <ConfirmationModal
            title={t('Exit Draw Boundary')}
            body={t(
              fields.length === 0 && !isMobile
                ? 'Are you sure you want to navigate away from this page? All progress will be lost.'
                : 'Are you sure you want to navigate away from this page? All progress on this field will be lost.'
            )}
            cancelButtonText={t('No, remain')}
            confirmButtonText={t('Yes, exit')}
            onClickConfirm={handleGoBackModalConfirm}
            onClickCancel={() => {
              setShowCancelBoundaryModal({ show: false });
            }}
          />
        )}
        {showDrawingWarning && (
          <RelocatePositionModal
            title={t('Relocate position of the boundary')}
            text={t('No products available for this location. Please use another location.')}
            onOkClick={onOkRelocateBoundaryModal}
          />
        )}
        {!isMobile && (
          <EnvironmentDrawer
            open={showEnvironmentDrawer}
            onClose={() => setShowEnvironmentDrawer({ show: false })}
            onUpdate={handleUpdateEnvironmentDrawer}
          />
        )}
        {showEditBoundaryModal && (
          <ConfirmationModal
            title={t('Exit Draw Boundary')}
            body={t(
              'Are you sure you want to navigate away from this page? All progress will be lost.'
            )}
            cancelButtonText={t('No, remain')}
            confirmButtonText={t('Yes, exit')}
            onClickConfirm={handleConfirmEditBoundary}
            onClickCancel={() => setShowEditBoundaryModal({ show: false })}
          />
        )}
        {showGetRecommendationModal &&
          fields?.length > 0 &&
          !recommenationValidateLoading &&
          (checkProductListEmptyResponse.isProductListEmpty ? (
            <ConfirmationModal
              id={'ServiceUnavailableModal'}
              title={t('Please note')}
              body={checkProductListEmptyResponse.errorMsg}
              cancelButtonText={t('Adjust inputs')}
              onClickCancel={() => flowActions.setShowGetRecommendationModal({ show: false })}
              buttonStyle="primary"
            />
          ) : noSeedsAvailable ? (
            <ConfirmationModal
              id={'ServiceUnavailableModal'}
              title={t('Please note')}
              body={t(
                'There are no seed varieties matching the provided criteria. Try changing the inputs please.'
              )}
              cancelButtonText={t('Adjust inputs')}
              onClickCancel={() => flowActions.setShowGetRecommendationModal({ show: false })}
              buttonStyle="primary"
            />
          ) : (
            <GetRecommendationModal
              title={t('Get Recommendation')}
              description={t('Get Recommendation description')}
              onCancelClick={onCancelRecommendationModal}
              onGetRecommendationClick={onGetRecommendationModal}
              country={localCountry}
            />
          ))}
        {showAgronomicWeightings && (
          <AgronomicWeightingsDrawer
            showAgronomicWeightings={Boolean(showAgronomicWeightings)}
            mobileActiveTab={mobileActiveTab}
            agronomicWeightings={agronomicWeightings}
            attributes={attributes}
            handleSetShowAgronomicWeightings={handleSetShowAgronomicWeightings}
            handleFillAgronomicWeightings={handleFillAgronomicWeightings}
          />
        )}
        {showLandIsNotArableModal && (
          <ConfirmationModal
            title={t('Field not on arable land')}
            body={t('Looks like your field is not on arable land. Please redraw the boundaries.')}
            confirmButtonText={t('Ok')}
            onClickConfirm={handleShowLandNotArableModal}
          />
        )}
      </Root>
      <LandscapeWarningMessageContainer
        className={
          isMobile && orientation === ORIENTATION_TYPE.LANDSCAPE && !showEnvironmentDrawer
            ? ORIENTATION_BASED_DISPLAY.SHOW_ON_LANDSCAPE
            : 'hide'
        }
      >
        {t('Landscape for cellphone message')}
      </LandscapeWarningMessageContainer>
    </>
  );
};

export default BoundaryWizard;
