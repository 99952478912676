/*
The API key for your Amplitude project is required. Optionally, a user ID and config object can be passed in this call.
The SDK can be used anywhere after it is initialized anywhere in an application.
*/

import { init } from '@amplitude/analytics-browser';
import { removeQueryParameter } from 'utils/urlSearchParams';

const getDeviceId = () => {
  const storedDeviceId = sessionStorage.getItem('amplitudeDeviceId');
  if (storedDeviceId !== null) {
    return storedDeviceId;
  }
  const deviceId = new URLSearchParams(window.location.search).get('deviceId');
  if (deviceId === null) {
    return undefined;
  }
  sessionStorage.setItem('amplitudeDeviceId', deviceId);
  removeQueryParameter('deviceId');
  return deviceId;
};

const initAmplitude = async () => {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    const data = init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`, undefined, {
      deviceId: getDeviceId(),
      defaultTracking: {
        pageViews: true,
      }
    });
    return data;
  }
  return false;
};

export { initAmplitude };
